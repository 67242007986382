<template>
    <div class="offset-md-3 col-md-6">
      <div 
        class="card"
      >
        <div
          class="card-body"
        >
          <h1>Frequently Asked Questions</h1>
          <p>Hieronder kunnen de veel gestelde vragen van de app ingezien worden</p>
          <Accordion
            :items="faqs"
            :show-edit-buttons="true"
            @update:content="activateSubmitButton"
          />
        </div>
        <div class="card-footer pt-0">
          <button 
              class="btn btn-dark btn-sm float-end d-flex align-items-center ms-2"
              @click="newFaqItem"
            >
              Item toevoegen <i class="fa fa-plus ms-2"></i>
            </button>
            <button 
              v-if="showSubmitButton"
              class="btn btn-success btn-sm float-end"
              @click="saveUpdatedItems"
            >
              Aanpassingen opslaan
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapActions } from 'vuex';
  
  import Accordion from '@/components/UI/Accordion/Accordion.vue';
  
  import { get, edit, add } from "@/api/providers/faq"
  
  export default {
    name: 'FAQ',
    components: {
        Accordion    
    },
  
    data() {
      return {
        faqs: [],
        showSubmitButton: false
      }
    },
  
    mounted() {
      this.getFaqItems()
    },
  
    methods: {
      ...mapActions('notification', {
        addNotification: 'add',
      }),
  
      activateSubmitButton() { this.showSubmitButton = true },
  
      handleSaveSuccess() {
        this.addNotification({
          description: `FAQ is opgeslagen.`,
          icon: {
            component: 'help',
          },
        });
  
        this.navigateToNewsItemOverview();
      },
  
      getFaqItems() {
        get()
          .then(
            data => this.faqs = data ?? []
          )
         .catch(error => error.default())
      },
  
      saveUpdatedItems()
      {
        this.saveFaqItems()
          .then(this.handleSaveSuccess)
          .catch(error => error.default())
      },
  
      saveFaqItems()
      {
        const faqs = this.faqs;
        return new Promise(function (resolve, reject) {
          for(const faq of faqs)
          {
            if(faq.id === 0) { 
              add(faq).catch(error => reject(error))
              continue
            }
  
            edit(faq).catch(error => reject(error))
          }
  
          resolve()
        })
      },
      
      newFaqItem()
      {
        this.faqs.push({ 
          id: 0,
          title: 'Nieuw Item',
          description: 'Nieuw Item'
        });
  
        this.activateSubmitButton()
      }
    }
  }
  
  </script>