<template>
  <div 
    class="accordion-item mb-3"
    >
    <h5 
      :id="'heading_' + id" 
      class="accordion-header"
    >
      <button 
        v-if="isInViewMode"
        class="accordion-button border-bottom font-weight-bold collapsed"
        type="button"
        :data-bs-toggle="isInViewMode ? 'collapse' : ''" 
        :data-bs-target="'#collapse_' + id" 
        aria-expanded="false"
        :aria-controls="'#collapse_' + id" 
      >
        {{ title }}
        <i 
          class="collapse-close fa text-xs pt-1 position-absolute end-0 me-3" 
          :class="isCollapsed ? 'fa-plus' : 'fa-minus'"
          aria-hidden="true"
        ></i>
      </button>

      <MaterialInput
        v-if="isInEditMode"
        v-model="titleInputContent"
        :value="title"
        is-required
        :label="titleLabel"
        class="mb-2"
        variant='static'
      />
    </h5>

    <div 
      :id="'collapse_' + id" 
      class="accordion-collapse collapse" 
      :aria-labelledby="'heading_' + id"
      data-bs-parent="#accordion" 
    >
      <div 
        v-if="isInViewMode"
        class="accordion-body text-sm opacity-8"
      >
        {{ description }}
  </div>
    <MaterialTextarea
      :label="descriptionLabel"
      v-model="descriptionInputContent"
      is-required
      v-if="isInEditMode"
      variant="static"
    />
    <div>
      <button 
        v-if="showEditButton"
        class="btn btn-sm btn-dark mt-2"
        @click="toggleMode"
      >
        {{ modeToggleButtonText }}
      </button>
    </div>
    </div>
  </div>
</template>

<script>
import MaterialInput from "@/components/MaterialInput"
import MaterialTextarea from "@/components/MaterialTextarea"

import { createVModelHandlers } from '@/helpers/vue';

const Modes = {
  EDIT: 0,
  VIEW: 1
}

const CollapseState = {
  COLLAPSED: 0,
  OPEN: 1
}

export default {
    name: 'AccordionItem',
    components: {
        MaterialInput,
        MaterialTextarea
    },
    props: {
        id: { type: Number, required: true },
        title: { type: String, required: true },
        description: { type: String, required: true },

        titleLabel: { type: String, default: 'Titel' },
        descriptionLabel: { type: String, default: 'Beschrijving' },

        showEditButton: { type: Boolean, default: false }
    },

    data() {
        return {
            // These store the updated content while the fields are being edited
            titleInputContent: this.title,
            descriptionInputContent: this.description,

            mode: Modes.VIEW,
            state: CollapseState.COLLAPSED,
        }
    },

    mounted() { 
      this.attachHideCollapseHook()
      this.attachShowCollapseHook()
    },

    computed: {
        isInViewMode() { return this.mode === Modes.VIEW },
        isInEditMode() { return this.mode === Modes.EDIT },

        isCollapsed() { return this.state === CollapseState.COLLAPSED },

        modeToggleButtonText() {
            return this.mode === Modes.VIEW
            ? "Aanpassen"
            : "Opslaan"
        },

        ...createVModelHandlers(
            'title',
            'description'
        ),
    },

    methods:
    {
      toggleMode() {
          this.mode === Modes.VIEW
          ? this.enterEditMode()
          : this.exitEditMode()
      },
      enterEditMode() { this.mode = Modes.EDIT },
      exitEditMode() { 
        this.mode = Modes.VIEW

        this.emitUpdatedTitle()
        this.emitUpdatedDescription()
      },

      emitUpdatedTitle() { if(this.titleInputContent !== this.title) this.$emit('update:title', this.titleInputContent) },
      emitUpdatedDescription() { if(this.descriptionInputContent !== this.description) this.$emit('update:description', this.descriptionInputContent) },

      attachHideCollapseHook() { this.$el.addEventListener('hide.bs.collapse', this.bootstrapHideCollapseHook) },
      attachShowCollapseHook() { this.$el.addEventListener('show.bs.collapse', this.bootstrapShowCollapseHook) },

      bootstrapHideCollapseHook() { 
        this.exitEditMode()
        this.state = CollapseState.COLLAPSED
      },

      bootstrapShowCollapseHook() { 
        this.state = CollapseState.OPEN
      }
    }
}
</script>