<template>
  <div id="accordion" class="accordion">
    <AccordionItem
      v-for="item in items"
      :id="item.id"
      :key="item.id"
      
      :title="item.title"
      :description="item.description"

      :show-edit-button="showEditButtons"

      @update:title="title => { 
        item.title = title
        handleContentChange()
      }"
      @update:description="description => {
        item.description = description
        handleContentChange()
      }"
    />
  </div>

  <div
    v-if="showSubmitButton"
    class="row justify-content-end"
  >
    <div class="col-md-3">
      <button class="btn btn-success btn-sm float-end">
        Aanpassingen opslaan
      </button>
    </div>
  </div>
</template>

<script>
import AccordionItem from './Item.vue'

export default {
  name: 'Accordion',
  components: { AccordionItem },
  props: {
    items: { type: Array, default: () => [] },
    showEditButtons: { type: Boolean, default: false }
  },

  emits: [
    'update:content'
  ],

  methods: {
    handleContentChange() {
      this.$emit('update:content')
    }
  },
}
</script>